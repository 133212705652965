<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/settings/payment-gateway`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading"
                :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-card>
                                    <v-card-title>{{ $t('Stripe') }}</v-card-title>
                                    <v-card-text>
                                        <v-checkbox v-model="item.payment_gateway_stripe_active" :label="$t('Active')" false-value="0" true-value="1"></v-checkbox>
                                        <v-text-field v-model="item.payment_gateway_stripe_key" :label="$t('Key')"></v-text-field>
                                        <v-text-field v-model="item.payment_gateway_stripe_secret" :label="$t('Secret')"></v-text-field>
                                        <v-text-field v-model="item.payment_gateway_stripe_webhook_secret" :label="$t('Webhook Secret')"></v-text-field>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ $t('Webhook endpoint URL') }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ $env.API_URL }}/api/v1/webhooks/stripe
                                                </v-list-item-subtitle>

                                                <v-list-item-title class="pt-4">{{ $t('Webhook events to send') }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <ul>
                                                        <li>invoice.payment_action_required</li>
                                                        <li>payment_method.automatically_updated</li>
                                                        <li>customer.deleted</li>
                                                        <li>customer.updated</li>
                                                        <li>customer.subscription.deleted</li>
                                                        <li>customer.subscription.updated</li>
                                                        <li>customer.subscription.created</li>
                                                    </ul>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import settingService from '@/services/settingService'

export default {
    data() {
        return {
            loading: false,
            item: {
                payment_gateway_stripe_active: "0",
                payment_gateway_stripe_key: null,
                payment_gateway_stripe_secret: null,
                payment_gateway_stripe_webhook_secret: null
            },
        }
    },
    async created() {
        await this.getSetting()
    },
    methods: {
        async getSetting() {
            try {
                const result = await settingService.get('payment-gateway')
                this.item = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                await settingService.edit('payment-gateway', this.item)
                this.$dialog.message.success(this.$t('Success'))
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        }
    },
}
</script>